import React, { useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Container, Div, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import { Input } from "../../components/Input";
import { theme } from "../../styles/theme";
import searchIcon from "../../assets/icons/search.svg";
import useDebounce from "../../core/hooks/useDebounce";
import edit from "../../assets/icons/edit.svg";
import { Skeleton } from "../../components/Skeleton";
import { Table } from "../../components/Table";
import { useEffect } from "react";
import useGetKeywordsList from "./hooks/useGetKeywordsList";
import back from "../../assets/icons/arrow-left.svg";

export const KeywordsManager = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const templateName = query.get('templateName'); 
  const { id } = useParams();
  const navigate = useNavigate();
  const [dataKeywords, setDataKeywords] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState({ current: 1, pageSize: 10 });
  const [searchBy, setSearchBy] = useState("");
  const searchValue = useDebounce(searchBy, 600);
  const [fieldName, setFieldName] = useState("");
  const getKeywords = useGetKeywordsList(
    id,
    pages.current,
    perPage,
    searchValue,
    fieldName,
  );
  const { data, isLoading } = getKeywords;

  const handleBack = () => {
    navigate(`/digitalization-documents/update-template/${id}`);
  };

  const handleEdit = (keyId) => {
    navigate(`/digitalization-documents/update-template/${id}/keywords-manager/edit-keywords/${keyId}?templateName=${templateName}`);
  };

  useEffect(() => {
    if (data?.data?.items.length > 0) {
      const dataTable = data?.data.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}`,
            id: ele.id,
            name: "Edit",
            icon: edit,
            color: theme.colors.salmon,
            onClick: () => handleEdit(ele.id),
          }
        ],
      }));

      setDataKeywords(dataTable);
      setTotalItems(data?.data.totalItems);
    } else {
      setDataKeywords([]);
    }
  }, [data?.data]);

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "mainColumnName",
      key: "mainColumnName",
      width: 200,
    },
    {
      title: "Category: General information or item",
      dataIndex: "isGeneralItem",
      key: "isGeneralItem",
      align: "center",
      width: 300,
      render: (isGeneralItem) => (
        <Div>
          <Text>{isGeneralItem ? "General information" : "Item"}</Text>
        </Div>
      ),
    },
    {
      title: "Keyword",
      dataIndex: "keywordsSummary",
      key: "keywordsSummary",
      width: 650,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "left",
      render: (actions) => (
        <Div gap="40px">
          {actions.map((action, i) => (
            <Link
              key={i}
              onClick={action.onClick}
              color={action.color}
              icon={
                <img
                  alt={`${action.icon}`}
                  src={action.icon}
                  style={{ marginRight: "8px" }}
                />
              }
              styles={{
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              {action.name}
            </Link>
          ))}
        </Div>
      ),
    },
  ];

  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setPages(pagination);
    setPerPage(pagination.pageSize);
    setAscendingOrder(sorter.order === 'ascend' ? true : false);
    setFieldName(sorter.field.charAt(0).toUpperCase() + sorter.field.substring(1));
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setPages({ current: 1, pageSize: 10 });
    setSearchBy(value);
  };

  return (
    <Container>
      <Div m="0 0 15px 0">
        <Link
          onClick={handleBack}
          color={theme.colors.grey1}
          icon={<img alt="back" src={back} style={{ marginRight: "12px" }} />}
        >
          Back
        </Link>
      </Div>
      <Div width="100%" justify="space-between" align="center">
        <Div direction="column" gap="10px">
          <Div
            direction="column"
            gap="6px"
            style={{
              width: "350px",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              wordBreak: "break-all",
            }}
          >
            <Text
              size={theme.fonts.size.h4}
              color={theme.colors.dark}
              weight={theme.fonts.weight.bold}
            >
              Keywords manager
            </Text>
          </Div>
          <Text
            size={theme.fonts.size.h5}
            color={theme.colors.grey1}
            weight={theme.fonts.weight.bold}
          >
            {templateName}
          </Text>
        </Div>
        <Div gap="24px" align="center">
          <Input
            placeholder="Search..."
            suffix={<img alt="search" src={searchIcon} />}
            width="324px"
            onChange={handleChangeSearch}
          />
        </Div>
      </Div>
      <Div m="32px 0 0 0">
        <Table
          columns={tableColumns}
          data={dataKeywords}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          sizerLabels={["Show", "Results"]}
          pagination={{
            defaultCurrent: 1,
            ...pages,
            total: totalItems,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
            locale: {
              items_per_page: "",
            },
            position: ["bottomCenter"],
          }}
          onChange={handleChangeTable}
          locale={{
            emptyText: 
            isLoading ? (
              <Div justify="center" gap="16px">
                {[...Array(9)].map((v, idx) => (
                  <Skeleton
                    title={false}
                    paragraph={{
                      rows: 10,
                      width: "100%",
                    }}
                    loading
                    key={idx}
                  />
                ))}
              </Div>
            ) : (
              "No data"
            ),
          }}
        />
      </Div>
    </Container>
  );
};
