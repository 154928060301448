import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PublicRoute } from "../components/PublicRoute/PublicRoute";
import { AllVersions } from "./AllVersions";
import { App } from "./App";
import { Companies } from "./Companies";
import { Login } from "./Login";
import { ProjectDetail } from "./ProjectDetail";
import { Projects } from "./Projects";
import { ResetPassword } from "./ResetPassword";
import { ResetPasswordRequest } from "./ResetPasswordRequest";
import { Sheet } from "./Sheet";
import { Documents } from "./Documents";
import { DigitalizationDocuments } from "./DigitalizationDocuments";
import { CreateTemplate } from "./CreateTemplate";
import { UpdateTemplate } from "./UpdateTemplate";
import { DocumentsDetail } from "./DocumentsDetail";
import { KeywordsManager } from "./KeywordsManager";
import { EditKeywords } from "./EditKeywords";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="reset-password-request"
            element={
              <PublicRoute>
                <ResetPasswordRequest />
              </PublicRoute>
            }
          />
          <Route
            path="reset-password"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route path="projects">
            <Route index element={<Projects />} />
            <Route path=":id">
              <Route index element={<ProjectDetail />} />
              <Route path="sheet/:sheetId" element={<Sheet />} />
              <Route path="sheet-versions/:sheetId" element={<AllVersions />} />
            </Route>
          </Route>
          <Route path="companies" element={<Companies />} />
          <Route path="documents/:id">
            <Route index element={<Documents />} />
            <Route path="detail/:docId" element={<DocumentsDetail />} />
          </Route>
          <Route path="digitalization-documents">
            <Route index element={<DigitalizationDocuments />} />
            <Route path="create-template" element={<CreateTemplate />} />
            <Route path="update-template/:id">
              <Route index element={<UpdateTemplate />} />
              <Route path="keywords-manager">
                <Route index element={<KeywordsManager />} />
                <Route path="edit-keywords/:keyId" element={<EditKeywords />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
