import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../Card";
import { Div, Text } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { Input } from "../Input";
import { Row, Col } from "antd";
import { Link } from "../Link";
import addIcon from "../../assets/icons/add.svg";
import { Button } from "../Button";
import closeIcon from "../../assets/icons/gray-close.svg";
import useUpdateKeywords from "../../pages/EditKeywords/hooks/useUpdateKeywords";
import toast from "react-hot-toast";

export const TemplateKeywordsForm = ({ keywordInfo, templateName }) => {
  const navigate = useNavigate();
  const { id, keyId } = useParams();
  const updateKeywords = useUpdateKeywords();
  const [keywordsForm, setKeywordsForm] = useState([
    {
      id: 1,
      keyWord: "",
    }
  ]);

  useEffect(() => {
    if (keywordInfo) {
      const newKeywordsForm = Array.from({ length: 16 }, (_, index) => {
        const keyWordValue = keywordInfo[`keyWord${index + 1}`];
        return keyWordValue ? { id: index + 1, keyWord: keyWordValue } : null;
      }).filter(Boolean);

      setKeywordsForm(newKeywordsForm);
    }
  }, [keywordInfo]);

  const handleChange = (event, indx) => {
    const { value } = event.target;
    const newInfo = [...keywordsForm];

    newInfo[indx].keyWord = value;
    setKeywordsForm(newInfo);
  };

  const handleAdd = () => {
    const newInfo = [...keywordsForm];
    setKeywordsForm([...newInfo, {
      id: newInfo.length + 1,
      keyWord: ""
    }]);
  };

  const handleSave = () => {
    const payload = {
      documentKeyWordId: keyId,
      ...Array.from({ length: 16 }, (_, index) => {
        const keyWordValue = keywordsForm[index] ? keywordsForm[index].keyWord : "";
        return { [`keyWord${index + 1}`]: keyWordValue };
      }).reduce((acc, curr) => ({ ...acc, ...curr }), {})
    };

    updateKeywords.reset();
    updateKeywords.mutate({...payload}, {
      onSuccess: () => {
        toast.success("Keywords updated successfully");
        navigate(`/digitalization-documents/update-template/${id}/keywords-manager?templateName=${templateName}`);
      },
      onError: (err) => {
        toast.error(err.response.data.Errors[0]);
      }
    });
  };

  const handleDelete = (indx) => {
    const newInfo = keywordsForm.filter((ele, index) => index !== indx);
    setKeywordsForm([...newInfo]); 
  };

  return (
    <>
      <Card 
        radius="15px"
        padding={"24px 33px 37px 33px"}
      >
        <Div direction="column" width="100%">
          <Text
            color={theme.colors.dark}
            size={theme.fonts.size.h5}
            weight={theme.fonts.weight.bold}
            mb="24px"
          >
            General Information
          </Text>
          <Input 
            id="name"
            width={"358px"}
            value={keywordInfo?.mainColumnName}
            disabled={true}
          />
          <Text size={theme.fonts.size.sm} color={theme.colors.grey1} mt="18px" mb="24px">
            Please provide the information for each of the editable fields in the document
          </Text>
          <Row gutter={[16, 24]} style={{ width: "100%", marginBottom: "18px" }}>
            {keywordsForm?.map((ele, indx) => (
              <Col md={8} key={indx}>
                <Input 
                  id={`keyWord${indx + 1}`}
                  placeholder={`Keyword ${indx + 1}`}
                  width={"358px"}
                  value={ele.keyWord} 
                  onChange={(e) => handleChange(e, indx)}
                  suffix={
                    indx !== 0 ? 
                    <img 
                      alt="close" 
                      src={closeIcon} 
                      style={{ cursor: 'pointer' }}
                      onClick={(event) => {
                        handleDelete(indx);
                        event.stopPropagation();
                      }}
                    /> 
                    : null
                  }
                />
              </Col>
            ))}
          </Row>
          {keywordsForm.length < 16 && (
            <Link width="min-content" onClick={handleAdd}>
              <Div align="center" gap="7px">
                <img alt="add" src={addIcon} />
                Add Field
              </Div>
            </Link>
          )}
        </Div>
      </Card>
      <Button 
        background={theme.colors.salmon} 
        onClick={handleSave}
        loading={updateKeywords.isLoading}
        style={{ marginTop: "60px"}}
      >
        {"Save"}
      </Button>
    </>
  )
};
