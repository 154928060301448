import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetDocumentList = (currentPage, pageSize, searchValue, projectId, sortField, sortOrder) => {
  const data = useQuery(
    ["documentList", currentPage, pageSize, searchValue, projectId, sortField, sortOrder],
    () =>
      axiosClient.get(
        `/DocumentType/GetDocumentList?projectId=${projectId}&currentPage=${currentPage}&pageSize=${pageSize}&searchValue=${searchValue}&sortField=${sortField}&sortOrder=${sortOrder}`
      ),
    { enabled: !!projectId }
  );

  return data;
};
export default useGetDocumentList;
