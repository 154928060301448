import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactLoading from "react-loading";
import { Container, Div, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import { theme } from "../../styles/theme";
import back from "../../assets/icons/arrow-left.svg";
import { useParams } from "react-router-dom";
import { TemplateKeywordsForm } from "../../components/Documents/TemplateKeywordsForm";
import useGetKeywordsInfo from "./hooks/useGetKeywordsInfo";
export const EditKeywords = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, keyId } = useParams();
  
  const queryParams = new URLSearchParams(location.search);
  const templateName = queryParams.get('templateName');

  const { data, isSuccess, isLoading } = useGetKeywordsInfo(keyId);
  const [keywordInfo, setKeywordInfo] = useState({});

  useEffect(() => {
    if (isSuccess) {
      setKeywordInfo(data?.data);
    }
  }, [isSuccess, data]);

  const handleBack = () => {
    navigate(`/digitalization-documents/update-template/${id}/keywords-manager?templateName=${templateName}`);
  }; 

  return (
    <Container>
      <Div m="0 0 15px 0">
        <Link
          onClick={handleBack}
          color={theme.colors.grey1}
          icon={<img alt="back" src={back} style={{ marginRight: "12px" }} />}
        >
          Back
        </Link>
      </Div>
      <Div width="100%" justify="space-between" align="center" m="0 0 26px 0">
        <Div
          direction="column"
          gap="6px"
          style={{
            width: "350px",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.dark}
            weight={theme.fonts.weight.bold}
          >
            Edit Keywords
          </Text>
          <Text size={theme.fonts.size.sm} color={theme.colors.grey2}>
            {templateName}
          </Text>
        </Div>
      </Div>
      {isLoading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.dark} />
        </Div>
      ) : (
        <TemplateKeywordsForm keywordInfo={keywordInfo} templateName={templateName} />
      )}
    </Container>
  )
};