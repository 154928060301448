import { useMutation } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";
import { toast } from "react-hot-toast";

const useCreateDocument = () => {
  const mutation = useMutation(
    async (payload) => {
      const formData = new FormData();
      formData.append("ProjectId", payload.projectId);
      formData.append("Notes", payload.notes);
      formData.append("DocumentTypeId", payload.documentTypeId);
      payload.blobDocuments.forEach((doc) =>
        formData.append("BlobDocuments", doc)
      );

      return axiosClient.post("/DocumentType/CreateDocument", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    {
      onMutate: () => {
        toast.loading("Creating document...", { id: "create-doc" });
      },
      onSuccess: () => {
        toast.success("Document created successfully", { id: "create-doc" });
      },
      onError: (error) => {
        toast.error(
          error.response?.data?.Errors?.[0] || "Error creating document",
          { id: "create-doc" }
        );
      },
    }
  );

  return mutation;
};

export default useCreateDocument;
