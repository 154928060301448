import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetKeywordsList = (documentTypeId, currentPage, pageSize, searchValue, fieldName) => {
  const data = useQuery(
    ["keywordsList", documentTypeId, currentPage, pageSize, searchValue, fieldName],
    () =>
      axiosClient.get(
        `/DocumentTypeKey/GetDocumentKeyWordsList?documentTypeId=${documentTypeId}&currentPage=${currentPage}&pageSize=${pageSize}&searchValue=${searchValue}&fieldName=${fieldName}`
      ),
  );

  return data;
};
export default useGetKeywordsList;