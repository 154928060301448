import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { Container, Div, Text } from "../../styles/Common";
import { Link } from "../../components/Link";
import { theme } from "../../styles/theme";
import back from "../../assets/icons/arrow-left.svg";
import { dateFormat } from "../../utilities/helpers";
import { TemplateForm } from "../../components/Documents/TemplateForm";
import { useParams } from "react-router-dom";
import useGetTemplateInfo from "./hooks/useGetTemplateInfo";
import { Button } from "../../components/Button";
import _ from "lodash";

export const UpdateTemplate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isSuccess, isLoading } = useGetTemplateInfo(id);
  const [templateInfo, setTemplateInfo] = useState({});

  useEffect(() => {
    if (isSuccess) {
      const templateInfo = data?.data.documentType;
      setTemplateInfo({
        ...templateInfo,
        generalInformation: _.orderBy(templateInfo.generalInformation, ['order'], ['asc'])
          .map(item => item.generalItemName),
        itemsInformation: _.orderBy(templateInfo.itemsInformation, ['order'], ['asc'])
          .map(item => item.itemName)
      });
    };
  }, [isSuccess, data]);

  const handleBack = () => {
    navigate(
      `/digitalization-documents`
    );
  };

  const handleKeywords = () => {
    navigate(`/digitalization-documents/update-template/${id}/keywords-manager?templateName=${templateInfo.name}`);
  };

  return (
    <Container>
      <Div m="0 0 15px 0">
        <Link
          onClick={handleBack}
          color={theme.colors.grey1}
          icon={<img alt="back" src={back} style={{ marginRight: "12px" }} />}
        >
          Back
        </Link>
      </Div>
      <Div width="100%" justify="space-between" align="center" m="0 0 26px 0">
        <Div
          direction="column"
          gap="6px"
          style={{
            width: "350px",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.dark}
            weight={theme.fonts.weight.bold}
          >
            Edit Document Type
          </Text>
          <Text size={theme.fonts.size.sm} color={theme.colors.grey2}>
            Creation date: {isLoading ? "" : dateFormat(templateInfo.creationDate)}
          </Text>
        </Div>
      </Div>
      <Div m="0 0 26px 0" justify="space-between" align="center">
        <Text
          size={theme.fonts.size.h5}
          color={theme.colors.dark}
          weight={theme.fonts.weight.bold}
        >
          {templateInfo.name}
        </Text>
        <Div>
          <Button background={theme.colors.salmon} color={theme.colors.white} onClick={handleKeywords}>
            Keywords Manager
          </Button>
        </Div>
      </Div>
      {isLoading ? (
        <Div width="100%" height="100%" justify="center" align="center">
          <ReactLoading color={theme.colors.dark} />
        </Div>
      ) : (
        <TemplateForm templateInfo={templateInfo} />
      )}
    </Container>
  )
};