import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetNextId = (documentId, projectId) => {
  const data = useQuery(
    ["nextId", documentId, projectId],
    () =>
      axiosClient.get(`/DocumentType/GetNextDocumentNeedReviewed?documentId=${documentId}&projectId=${projectId}`),
    { enabled: !!documentId && !!projectId, refetchOnWindowFocus: false }
  );

  return data;
};
export default useGetNextId;
