import React, { useState } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Container, Div, Text } from "../../styles/Common";
import { useNavigate } from "react-router-dom";
import { Link } from "../../components/Link";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import searchIcon from "../../assets/icons/search.svg";
import useDebounce from "../../core/hooks/useDebounce";
import trash from "../../assets/icons/trash-salmon.svg";
import edit from "../../assets/icons/edit.svg";
import { Skeleton } from "../../components/Skeleton";
import { Table } from "../../components/Table";
import { useEffect } from "react";
import { dateFormat } from "../../utilities/helpers";
import alert from "../../assets/icons/alert.svg";
import { Modal } from "../../components/Modal";
import { Alert } from "antd";
import useGetDigDocumentList from "./hooks/useGetDigDocumentList";
import useDeleteTemplate from "./hooks/useDeleteTemplate";

export const DigitalizationDocuments = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const deleteTemplate = useDeleteTemplate();
  const [errorMessage, setErrorMessage] = useState("");
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [dataDocuments, setDataDocuments] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState({ current: 1, pageSize: 10 });
  const [searchBy, setSearchBy] = useState("");
  const searchValue = useDebounce(searchBy, 600);
  const [fieldName, setFieldName] = useState("");
  const [ascendingOrder, setAscendingOrder] = useState(false);
  const getDocuments = useGetDigDocumentList(
    pages.current,
    perPage,
    searchValue,
    fieldName,
    ascendingOrder
  );
  const { data, isLoading } = getDocuments;
  const [modalInfo, setModalInfo] = useState({
    icon: "",
    title: "",
    message: "",
    okButtonText: "",
    cancelButtonText: "",
    okButton: () => {},
    cancelButton: () => {},
  });

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = (id) => {
    deleteTemplate.reset();
    deleteTemplate.mutate(id, {
      onSuccess: () => {
        toast.success(`The tamplate has been deleted successfully`);
        queryClient.invalidateQueries([
          "digDocumentList",
          pages.current,
          perPage,
          searchValue,
          fieldName,
          ascendingOrder
        ]);
        setOpenModalDelete(false);
      },
      onError: (err) => {
        setErrorMessage(err.response.data.Errors[0]);
      },
    });
  };

  const handleModalDelete = (id) => {
    setOpenModalDelete(true);
    setModalInfo({
      icon: alert,
      title: "Are you sure to delete the document?",
      okButtonText: "Yes, remove",
      cancelButtonText: "No, cancel",
      okButton: () => handleDelete(id),
      cancelButton: handleClose,
    });
  };

  const handleEdit = (id) => {
    navigate(`/digitalization-documents/update-template/${id}`)
  }

  useEffect(() => {
    if (data?.data?.documentTypes?.items.length > 0) {
      const dataTable = data?.data.documentTypes.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}`,
            id: ele.id,
            name: "Edit",
            icon: edit,
            color: theme.colors.salmon,
            onClick: () => handleEdit(ele.id),
          },
          {
            key: `1${i}`,
            id: ele.id,
            name: "Delete",
            icon: trash,
            color: theme.colors.salmon,
            onClick: () => handleModalDelete(ele.id),
          },
        ],
      }));

      setDataDocuments(dataTable);
      setTotalItems(data?.data.documentTypes.totalItems);
    } else {
      setDataDocuments([]);
    }
  }, [data?.data]);

  const tableColumns = [
    {
      title: "Creation date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 200,
      render: (text) => dateFormat(text),
    },
    {
      title: "Document type name",
      dataIndex: "name",
      key: "name",
      width: 550,
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Items",
      dataIndex: "items",
      key: "items",
      width: 200,
      render: (actions) => (
        <Text color={theme.colors.grey1}>
          {actions ? "Yes" : "No"}
        </Text>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "left",
      render: (actions) => (
        <Div gap="40px">
          {actions.map((action, i) => (
            <Link
              key={i}
              onClick={action.onClick}
              color={action.color}
              icon={
                <img
                  alt={`${action.icon}`}
                  src={action.icon}
                  style={{ marginRight: "8px" }}
                />
              }
              styles={{
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              {action.name}
            </Link>
          ))}
        </Div>
      ),
    },
  ];

  const handleChangeTable = (pagination, filters, sorter, extra) => {
    setPages(pagination);
    setPerPage(pagination.pageSize);
    setAscendingOrder(sorter.order === 'ascend' ? true : false);
    setFieldName(sorter.field.charAt(0).toUpperCase() + sorter.field.substring(1));
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setPages({ current: 1, pageSize: 10 });
    setSearchBy(value);
  };

  const handleCreate = () => {
    navigate("/digitalization-documents/create-template")
  };

  return (
    <Container>
      <Modal
        open={openModalDelete}
        onCancel={handleClose}
        align={"center"}
        padding={36}
      >
        <Modal.Header
          icon={modalInfo.icon}
          iconBackground={theme.colors.salmon}
          iconWidth={"24px"}
          iconHeight={"24px"}
          align={"-webkit-center"}
          padding="16px 0 16px 0"
          title={modalInfo.title}
        />
        <Modal.Footer style={{ flexDirection: "column" }}>
          {errorMessage !== "" && (
            <Div m="0 0 20px 0" justify="flex-end">
              <Alert closable message={`${errorMessage}`} type="error" />
            </Div>
          )}
          <Div gap="32px" justify="center">
            {modalInfo.cancelButtonText && (
              <Button
                onClick={modalInfo.cancelButton}
                border={theme.colors.dark}
                color={theme.colors.dark}
              >
                {modalInfo.cancelButtonText}
              </Button>
            )}
            {modalInfo.okButtonText && (
              <Button
                onClick={modalInfo.okButton}
                background={theme.colors.salmon}
              >
                {modalInfo.okButtonText}
              </Button>
            )}
          </Div>
        </Modal.Footer>
      </Modal>
      <Div width="100%" justify="space-between" align="center">
        <Div
          direction="column"
          gap="6px"
          style={{
            width: "350px",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-all",
          }}
        >
          <Text
            size={theme.fonts.size.h4}
            color={theme.colors.dark}
            weight={theme.fonts.weight.bold}
          >
            Document type
          </Text>
        </Div>
        <Div gap="24px" align="center">
          <Input
            placeholder="Search..."
            suffix={<img alt="search" src={searchIcon} />}
            width="324px"
            onChange={handleChangeSearch}
          />
          <Button background={theme.colors.salmon} onClick={handleCreate}>
            Create Template
          </Button>
        </Div>
      </Div>
      <Div m="32px 0 0 0">
        <Table
          columns={tableColumns}
          data={dataDocuments}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          sizerLabels={["Show", "Results"]}
          pagination={{
            defaultCurrent: 1,
            ...pages,
            total: totalItems,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
            locale: {
              items_per_page: "",
            },
            position: ["bottomCenter"],
          }}
          onChange={handleChangeTable}
          locale={{
            emptyText: 
            isLoading ? (
              <Div justify="center" gap="16px">
                {[...Array(9)].map((v, idx) => (
                  <Skeleton
                    title={false}
                    paragraph={{
                      rows: 10,
                      width: "100%",
                    }}
                    loading
                    key={idx}
                  />
                ))}
              </Div>
            ) : (
              "No data"
            ),
          }}
        />
      </Div>
    </Container>
  );
};
