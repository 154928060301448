import React, { useState } from "react";
import { CustomCollapse } from "./style";
import arrowUp from "../../assets/icons/arrow-up-dark.svg";
import arrowDown from "../../assets/icons/arrow-down-dark.svg";
import { Divider } from "antd";
import { theme } from "../../styles/theme";
import { Div, Text } from "../../styles/Common";
import { Button } from "../Button";
import Checkbox from "../Checkbox";

const { Panel } = CustomCollapse;

export const Collapse = ({ 
  panels, 
  detail, 
  documentFlag, 
  setDocumentFlag, 
  handleUpdate,
  handleUpdateAndContinue,
  nextNeedReviewId,
  isLoading 
}) => {
  const initKey = detail ? "MainPanel-1" : "1";
  const [activeKey, setActiveKey] = useState(initKey);

  const handlePanelChange = (key) => {
    setActiveKey(key);
  };

  return (
    <CustomCollapse
      accordion
      activeKey={activeKey}
      onChange={handlePanelChange}
      expandIcon={({ isActive }) =>
        isActive ? (
          <img alt="arrow-up" src={arrowUp} />
        ) : (
          <img alt="arrow-down" src={arrowDown} />
        )
      }
      expandIconPosition={"end"}
      style={{ 
        background: detail ? theme.colors.white : theme.colors.light, 
        border: "none",
        width: "100%",
        boxShadow: detail ? "0px 9px 20px #00000008" : null, 
      }}
      detail={detail}
    >
      {panels.map((ele, indx) => (
        <>
          <Panel
            id={indx}
            key={ele.key}
            header={ele.header}
            extra={ele.extra}
            style={{
              marginBottom: detail ? 0 : ele.key === "1" ? 21 : 0,
              background: theme.colors.white,
              boxShadow: detail ? null : "0px 9px 20px #00000008",
              borderRadius: detail ? "15px" : "10px",
              opacity: 1,
              border: `1px solid ${theme.colors.white}`,
            }}
          >
            {!detail && <Divider style={{ margin: "0px" }} />}
            {ele.panel}
          </Panel>
          {detail && (
            <Divider style={{ margin: "0px" }} />
          )}
        </>
      ))}
      {detail && (
        <Div direction="column" gap="24px" p="30px 0 29px 27px">
          <Div align="center" gap="8px">
            <Checkbox checked={documentFlag} onChange={(e) => setDocumentFlag(e.target.checked)} />
            <Text
              color={theme.colors.dark}
              size={theme.fonts.size.sm}
            >
              Flag Document
            </Text>
          </Div>
          <Div 
            direction="column" 
            gap="10px" 
            justify={"center"}
            align={"center"} 
            style={{ marginRight: "27px" }}
          >
            <Button 
              background={theme.colors.salmon} 
              width={"100%"}
              onClick={handleUpdate}
              loading={isLoading}
            >
              Save information
            </Button>
            {nextNeedReviewId && (
              <Button 
                border={theme.colors.salmon} 
                color={theme.colors.salmon}
                background={theme.colors.white}
                width={"100%"}
                onClick={handleUpdateAndContinue}
                loading={isLoading}
            >
                Save and continue
              </Button>
            )}
          </Div>
        </Div>
      )}
    </CustomCollapse>
  );
};
